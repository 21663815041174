import {
    Create,
    maxLength,
    NumberInput,
    ReferenceInput,
    required,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'react-admin';

export const NtaCategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), maxLength(75)]} />
            <ReferenceInput
                label="Section"
                source="section"
                reference="nta/section"
            >
                <SelectInput optionText="name" validate={[required(), maxLength(75)]} />
            </ReferenceInput>
            <NumberInput source="orderKey" initialValue={0} step={1} validate={[required()]} />
        </SimpleForm>
    </Create>
);
