import * as React from 'react';
import { List, Datagrid, TextField, EmailField } from 'react-admin';

export const NgPlatformMemberList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <EmailField source="email" />
            <TextField source="slackId" />
        </Datagrid>
    </List>
);
