import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    BooleanField,
} from 'react-admin';
import { FreelancerFilters } from './FreelancerFilter';

export const NgPlatformFreelancerList = (props) => 
     (<List filters={<FreelancerFilters />} sort={{ field: 'name', order: 'ASC' }} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <EmailField source="email" />
            <BooleanField source="isAssigned" />
            <TextField source="dedicatedSlackChannel" sortable={false} />
            <TextField source="slackId" sortable={false} />
            <ReferenceArrayField
                label="Skills and Technologies"
                reference="ng-platform/skill"
                source="skillsAndTechnologies"
                sortable={false}
            >
                <SingleFieldList>
                    <ChipField source="fullName" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="note" sortable={false} />
        </Datagrid>
    </List>)

