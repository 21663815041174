import * as React from 'react';
import { AutocompleteInput, Edit, SimpleForm, TextInput } from 'react-admin';

export const NeilEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <AutocompleteInput
                source="permissions"
                choices={[
                    { id: 'Protected', name: 'Protected' },
                    { id: 'Unprotected', name: 'Unprotected' },
                ]}
            />
        </SimpleForm>
    </Edit>
);
