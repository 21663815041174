import {
    BooleanInput,
    Create,
    SimpleForm,
    AutocompleteArrayInput,
    SelectInput,
} from 'react-admin';

import { DAYS } from '../../../consts/days';

import { getHours } from '../../../utils/hours';

export const NotifyUnassignedQAsCadencyCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <AutocompleteArrayInput source="days" choices={DAYS} />
            <SelectInput source="hour" choices={getHours()} />
            <BooleanInput defaultValue={false} label="Active" source="active" />
        </SimpleForm>
    </Create>
);
