export enum GroupsList {
    BOB_ADMIN = 'BOB_ADMIN',
    DEVOPS_SHIFT_EDITOR = 'DEVOPS_SHIFT_EDITOR',
    SUPPORT_SHIFT_EDITOR = 'SUPPORT_SHIFT_EDITOR',
    NG_PLATFORM = 'NG_PLATFORM',
    NEIL_ADMIN = 'NEIL_ADMIN',
    NTA_CATEGORY_EDITOR = 'NTA_CATEGORY_EDITOR',
    FRONTEND_BENCH = "FRONTEND_BENCH",
    QA_BENCH = "QA_BENCH",
}
