import * as React from 'react';

import {
    Datagrid,
    DateField,
    List,
    TextField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
    ReferenceField,
    BooleanField,
    NumberField,
} from 'react-admin';
import Button from '@material-ui/core/Button';

export const SlackRedirectButton = ({ record = {} }: { record?: any }) => {
    return (
        <Button
            variant="contained"
            color="primary"
            disabled={!record.summaryPermalink}
            onClick={() => window.open(record.summaryPermalink)}
        >
            View at slack
        </Button>
    );
};

export const NgPlatformRequestList = (props) => (
    <List {...props}>
        <Datagrid>
            <TextField source="reqTitle" />
            <NumberField
                source="reqFreelancersAcceptedCount"
                label="Freelancers Accepted"
            />
            <NumberField
                source="reqAgenciesAcceptedCount"
                label="Agencies Accepted"
            />
            <ReferenceArrayField
                label="Skills and Technologies"
                reference="ng-platform/skill"
                source="reqSkillsTechnologies"
            >
                <SingleFieldList>
                    <ChipField source="fullName" />
                </SingleFieldList>
            </ReferenceArrayField>
            <BooleanField source="omitAssigned" />
            <ReferenceField
                label="Contact person"
                source="reqContactPerson"
                reference="ng-platform/member"
            >
                <TextField source="name" />
            </ReferenceField>
            <DateField source="reqDueDate" />
            <DateField source="reqStartDate" />
            <DateField source="createdAt" />
            <SlackRedirectButton />
        </Datagrid>
    </List>
);
