import {
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
} from 'react-admin';

export const AgencyFilters = (props) => 
    (<Filter {...props}>
        <TextInput
            label="Agency name"
            source="name"
            perPage={100}
            useRedirect="ng-platform/agencies"
        >
        </TextInput>
        <ReferenceInput
            label="Tags"
            source="tag"
            reference="ng-platform/tag"
            perPage={100}
            useRedirect="ng-platform/agencies"
        >
        <SelectInput optionText="name" />
        </ReferenceInput>
    </Filter>
);
