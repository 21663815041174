import { Edit, maxLength, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

export const NtaSectionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), maxLength(75)]} />
            <NumberInput source="orderKey" step={1} validate={[required()]} />
        </SimpleForm>
    </Edit>
);
