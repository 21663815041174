import * as React from 'react';
import {
    AutocompleteInput,
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

export const NgPlatformSkillEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <AutocompleteInput
                source="seniority"
                choices={[
                    { id: 'junior', name: 'junior' },
                    { id: 'regular', name: 'regular' },
                    { id: 'senior', name: 'senior' },
                ]}
            />
            <ReferenceInput
                label="category"
                source="category"
                reference="ng-platform/category"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
