import {
    DateInput,
    AutocompleteInput,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import * as React from 'react';

export const ShiftEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <AutocompleteInput
                source="status"
                choices={[
                    { id: 'ongoing', name: 'ongoing' },
                    { id: 'finished', name: 'finished' },
                    { id: 'planned', name: 'planned' },
                ]}
            />
            <TextInput source="channelId" disabled />
            <DateInput source="startDateTime" />
            <DateInput source="endDateTime" />
            <TextInput source="notificationTime" />
            <DateInput source="lastNotificationDateTime" />
        </SimpleForm>
    </Edit>
);
