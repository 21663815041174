import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    BooleanInput,
} from 'react-admin';

export const NgPlatformFreelancerEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <ReferenceArrayInput
                perPage={100}
                reference="ng-platform/skill"
                source="skillsAndTechnologies"
            >
                <SelectArrayInput optionText={'fullName'} />
            </ReferenceArrayInput>
            <TextInput source="name" />
            <BooleanInput label="Is assigned" source="isAssigned" />
            <TextInput source="email" />
            <TextInput source="dedicatedSlackChannel" resettable />
            <TextInput source="slackId" disabled />
            <TextInput source="note" resettable />
        </SimpleForm>
    </Edit>
);
