import React, { useState, useEffect } from 'react';
import { useRedirect, useLogin, defaultTheme, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        textAlign: 'center',
    },
});

export const SlackAuthRedirect = () => {
    const redirect = useRedirect();
    const [authState, setAuthState] = useState('Waiting for authorization...');
    const login = useLogin();
    const classes = useStyles();

    useEffect(() => {
        const sendCode = async () => {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('code');
            if (!code) {
                setAuthState('Authorization Failed! Redirecting...');
                setTimeout(() => {
                    redirect('/login');
                }, 1000);
                return;
            }
            await login(code);
            setTimeout(() => {
                redirect('/');
            }, 1000);
        };
        sendCode();
    }, [login, redirect]);

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <div className={classes.root}>{authState}</div>
            <Notification />
        </ThemeProvider>
    );
};
