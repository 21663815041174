import {
    DateInput,
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';
import * as React from 'react';

export const HolidayEdit = (props: any) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="name" />
            <DateInput source="holidayDate" />
        </SimpleForm>
    </Edit>
);
