import * as React from 'react';
import {
    Admin,
    AuthProvider,
    Resource,
} from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { MyLoginPage } from './components/LoginPage';
import { createBrowserHistory as createHistory } from 'history';
import customRoutes, { CustomRedirect } from './components/CustomRoutes';
import { fetchUtils } from 'ra-core';
import { ShiftList } from './components/shifts/ShiftList';
import { ShiftEdit } from './components/shifts/ShiftEdit';
import config from './config';
import jwtDecode from 'jwt-decode';
import { GroupsList } from './consts/groupsList.const';
import { NgPlatformRequestList } from './components/ng-platform/requests/RequestList';
import { NgPlatformMemberList } from './components/ng-platform/members/MemberList';
import { NgPlatformSkillList } from './components/ng-platform/skills/SkillList';
import { NgPlatformFreelancerList } from './components/ng-platform/freelancers/FreelancerList';
import { NgPlatformFreelancerEdit } from './components/ng-platform/freelancers/FreelancerEdit';
import { NgPlatformMemberEdit } from './components/ng-platform/members/MemberEdit';
import { NgPlatformSkillEdit } from './components/ng-platform/skills/SkillEdit';
import { NgPlatformAgencyEdit } from './components/ng-platform/agencies/AgencyEdit';
import { NgPlatformAgencyList } from './components/ng-platform/agencies/AgencyList';
import { NgPlatformSkillCreate } from './components/ng-platform/skills/NgPlatformCreate';
import { NgPlatformFreelancerCreate } from './components/ng-platform/freelancers/FreelancerCreate';
import { NgPlatformAgencyCreate } from './components/ng-platform/agencies/AgencyCreate';
import { NgPlatformMemberCreate } from './components/ng-platform/members/MemberCreate';
import { NgPlatformTagList } from './components/ng-platform/tags/TagList';
import { NgPlatformTagCreate } from './components/ng-platform/tags/TagCreate';
import { NeilList } from './components/neil/NeilList';
import { NeilEdit } from './components/neil/NeilEdit';
import { NeilCreate } from './components/neil/NeilCreate';
import { NgPlatformCategoryList } from './components/ng-platform/categories/CategoryList';
import { NgPlatformCategoryCreate } from './components/ng-platform/categories/CategoryCreate';
import { NgPlatformCategoryEdit } from './components/ng-platform/categories/CategoryEdit';
import { NgPlatformTagEdit } from './components/ng-platform/tags/TagEdit';
import { NtaCategoryList } from './components/nta/category/CategoryList';
import { NtaCategoryEdit } from './components/nta/category/CategoryEdit';
import { NtaCategoryCreate } from './components/nta/category/CategoryCreate';
import { NtaSectionList } from './components/nta/section/SectionList';
import { NtaSectionEdit } from './components/nta/section/SectionEdit';
import { NtaSectionCreate } from './components/nta/section/SectionCreate';
import { OmitFrontendDevelopersCreate } from './components/frontend/omitedDevelopers/OmiFrontendDeveloperCreate';
import { OmitFrontendDevelopersEdit } from './components/frontend/omitedDevelopers/OmiFrontendDeveloperEdit';
import { OmitFrontendDevelopersList } from './components/frontend/omitedDevelopers/OmiFrontendDeveloperList';
import { OmitQAList } from './components/qa/omit-qa/OmitQAList';
import { OmitQAEdit } from './components/qa/omit-qa/OmitQAEdit';
import { OmitQACreate } from './components/qa/omit-qa/OmitQACreate';
import { NotifyUnassignedQAsCadencyList } from './components/qa/notify-qas-cadency/NotifyQAsCadencyList';
import { NotifyUnassignedQAsCadencyEdit } from './components/qa/notify-qas-cadency/NotifyQAsCadencyEdit';
import { NotifyUnassignedQAsCadencyCreate } from './components/qa/notify-qas-cadency/NotifyQAsCadencyCreate';
import { HolidayEdit } from "./components/toggl/HolidayEdit";
import { HolidayList } from "./components/toggl/HolidayList";
import { HolidayCreate } from "./components/toggl/HolidayCreate";

const httpClient = (url, options: any) => {
    if (!options) {
        options = {
            headers: undefined,
        };
    }
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const auth = localStorage.getItem('auth');
    if (auth) {
        const {access_token} = JSON.parse(auth);
        options.headers.set('Authorization', `Bearer ${access_token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(
    `${config.API_URL}/api/plugins`,
    httpClient,
);

const authProvider: AuthProvider = {
    login: async (code: string) => {
        const body = {
            code,
        };
        const response = await fetch(`${config.API_URL}/api/auth/slack`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            const payload = await response.json();
            const decodedToken = jwtDecode(payload.access_token) as any;
            localStorage.setItem('auth', JSON.stringify(payload));
            if (decodedToken.groups.length) {
                localStorage.setItem(
                    'groups',
                    JSON.stringify(decodedToken.groups),
                );
            } else {
                localStorage.setItem('groups', JSON.stringify(['GUEST']));
            }
        } else {
            throw new Error('Auth error');
        }
    },
    checkError: (error: any) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('groups');
        return Promise.resolve();
    },
    getPermissions: (params: any) => {
        const groups = localStorage.getItem('groups');
        return groups
            ? Promise.resolve(JSON.parse(groups))
            : Promise.resolve(['GUEST']);
    },
};

const history = createHistory();

const App = () => {
    return (
        <Admin
            loginPage={MyLoginPage}
            authProvider={authProvider}
            dataProvider={dataProvider}
            history={history}
            customRoutes={customRoutes}
        >
            {(permissions) => [
                [GroupsList.BOB_ADMIN].some((r) => permissions.includes(r)) ? (
                    <Resource name="toggl-holidays"
                              list={HolidayList}
                              edit={HolidayEdit}
                              create={HolidayCreate}
                    />
                ) : null,
                [
                    GroupsList.BOB_ADMIN,
                    GroupsList.SUPPORT_SHIFT_EDITOR,
                ].some((r) => permissions.includes(r)) ? (
                    <Resource
                        name="support-shifts"
                        list={ShiftList}
                        edit={ShiftEdit}
                    />
                ) : null,
                [
                    GroupsList.BOB_ADMIN,
                    GroupsList.DEVOPS_SHIFT_EDITOR,
                ].some((r) => permissions.includes(r)) ? (
                    <Resource
                        name="devops-shifts"
                        list={ShiftList}
                        edit={ShiftEdit}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/request"
                        list={NgPlatformRequestList}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/member"
                        list={NgPlatformMemberList}
                        edit={NgPlatformMemberEdit}
                        create={NgPlatformMemberCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/skill"
                        list={NgPlatformSkillList}
                        edit={NgPlatformSkillEdit}
                        create={NgPlatformSkillCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/freelancer"
                        list={NgPlatformFreelancerList}
                        edit={NgPlatformFreelancerEdit}
                        create={NgPlatformFreelancerCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/agency"
                        list={NgPlatformAgencyList}
                        edit={NgPlatformAgencyEdit}
                        create={NgPlatformAgencyCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/tag"
                        list={NgPlatformTagList}
                        create={NgPlatformTagCreate}
                        edit={NgPlatformTagEdit}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NG_PLATFORM].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="ng-platform/category"
                        list={NgPlatformCategoryList}
                        create={NgPlatformCategoryCreate}
                        edit={NgPlatformCategoryEdit}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.NEIL_ADMIN].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="neil"
                        list={NeilList}
                        edit={NeilEdit}
                        create={NeilCreate}
                    />
                ) : null,

                [
                    GroupsList.BOB_ADMIN,
                    GroupsList.NTA_CATEGORY_EDITOR,
                ].some((r) => permissions.includes(r)) ? (
                    <Resource
                        name="nta/category"
                        list={NtaCategoryList}
                        edit={NtaCategoryEdit}
                        create={NtaCategoryCreate}
                    />
                ) : null,

                [
                    GroupsList.BOB_ADMIN,
                    GroupsList.NTA_CATEGORY_EDITOR,
                ].some((r) => permissions.includes(r)) ? (
                    <Resource
                        name="nta/section"
                        list={NtaSectionList}
                        edit={NtaSectionEdit}
                        create={NtaSectionCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.FRONTEND_BENCH].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="frontend-admin/omit-user"
                        list={OmitFrontendDevelopersList}
                        edit={OmitFrontendDevelopersEdit}
                        create={OmitFrontendDevelopersCreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.QA_BENCH].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="qa-admin/omit-user"
                        list={OmitQAList}
                        edit={OmitQAEdit}
                        create={OmitQACreate}
                    />
                ) : null,

                [GroupsList.BOB_ADMIN, GroupsList.QA_BENCH].some((r) =>
                    permissions.includes(r),
                ) ? (
                    <Resource
                        name="qa-admin/notify-unassigned-qas-cadency"
                        list={NotifyUnassignedQAsCadencyList}
                        edit={NotifyUnassignedQAsCadencyEdit}
                        create={NotifyUnassignedQAsCadencyCreate}
                    />
                ) : null,

                ['GUEST'].some((r) => permissions.includes(r)) ? (
                    <Resource name={'redirect'} list={CustomRedirect}/>
                ) : null,
            ]}
        </Admin>
    );
};

export default App;
