import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
} from 'react-admin';

export const NgPlatformAgencyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <ReferenceArrayInput
                reference="ng-platform/tag"
                source="tags"
                perPage={100}
            >
                <SelectArrayInput optionText={'name'} />
            </ReferenceArrayInput>
            <TextInput source={'slackChannelId'} />
            <TextInput source="contactPersonEmail" />
        </SimpleForm>
    </Edit>
);
