import { Datagrid, List, TextField } from 'react-admin';

export const OmitFrontendDevelopersList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="slackId" />
            <TextField source="email" />
            <TextField source="realName" />
        </Datagrid>
    </List>
);
