import { AutocompleteArrayInput, BooleanInput, Edit, SelectInput, SimpleForm, TextInput } from 'react-admin';
import { DAYS } from '../../../consts/days';
import { getHours } from '../../../utils/hours';

export const NotifyUnassignedQAsCadencyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <AutocompleteArrayInput source="days" choices={DAYS} />
            <SelectInput source="hour" choices={getHours()} />
            <BooleanInput defaultValue={false} label="Active" source="active" />
            <TextInput source="title" disabled />
        </SimpleForm>
    </Edit>
);
