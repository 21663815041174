import { Datagrid, DateField, EditButton, List, TextField } from 'react-admin';
import * as React from 'react';

export const HolidayList = (props: any) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <DateField source="holidayDate" />
            <EditButton />
        </Datagrid>
    </List>
);
