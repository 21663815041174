import {
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';

export const OmitQAEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="slackId" />
            <TextInput source="email" disabled />
            <TextInput source="realName" disabled />
        </SimpleForm>
    </Edit>
);
