import { Datagrid, List, NumberField, ReferenceField, TextField } from 'react-admin';

export const NtaCategoryList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField source="section" reference="nta/section">
                <TextField source="name" />
            </ReferenceField>
            <NumberField source="orderKey" />
        </Datagrid>
    </List>
);
