export const getHours = () => {
  return Array.from({ length: 11 }, (_, i) => {
      const hour = 8 + i;
      return {
          id: hour,
          name: hour > 12
          ? `${hour - 12}:00 PM`
          : `${hour}:00 ${hour === 12 ? 'PM' : 'AM'}`
      }
  });
};