import { Datagrid, List, TextField, BooleanField } from 'react-admin';

export const NotifyUnassignedQAsCadencyList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField sortable={false} source="title" />
            <BooleanField sortable={false} source="active" />
        </Datagrid>
    </List>
);
