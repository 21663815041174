import {
    Create,
    SimpleForm,
    TextInput,
} from 'react-admin';

export const OmitFrontendDevelopersCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="slackId" />
        </SimpleForm>
    </Create>
);
