import * as React from 'react';
import { List, Datagrid, TextField, DeleteButton } from 'react-admin';

export const NeilList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="permissions" />
            <DeleteButton />
        </Datagrid>
    </List>
);
