import * as React from 'react';
import { Route } from 'react-router-dom';
import { SlackAuthRedirect } from './SlackAuthRedirect';
import { useGetPermissions, useLogout } from 'react-admin';
import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        textAlign: 'center',
    },
});

export const CustomRedirect = () => {
    const getPermissions = useGetPermissions();
    const logout = useLogout();
    const classes = useStyles();
    const [permissions, setPermissions] = useState<any>([]);
    useEffect(() => {
        getPermissions().then((permissions) => setPermissions(permissions));
    }, [getPermissions]);

    const handleRedirect = () => window.location.assign('/');
    const handleLogout = () => logout();

    return (
        <div className={classes.root}>
            <div>
                {permissions.includes('GUEST') ? (
                    <Button variant="contained" onClick={handleLogout}>
                        No sufficient permissions - Logout
                    </Button>
                ) : (
                    <Button variant="contained" onClick={handleRedirect}>
                        Go to dashboard...
                    </Button>
                )}
            </div>
        </div>
    );
};

const customRoutes = [
    <Route exact path="/auth/slack-redirect" component={SlackAuthRedirect} />,
];

export default customRoutes;
