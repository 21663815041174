import {
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleForm,
    TextInput,
    Create,
    BooleanInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';
import * as React from 'react';
import { isEmailValid } from '../../../utils/validation';

export const NgPlatformFreelancerCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();

    const validateFreelancerCreation = ({ name, email, dedicatedSlackChannel, skillsAndTechnologies }) => {
        const errors: Record<string, string> = {};

        if (!skillsAndTechnologies?.length) {
            errors.skillsAndTechnologies = 'Please provide skills';
        }

        if (!name) {
            errors.name = 'The name is required';
        }

        if (!email) {
            errors.email = 'The email is required';
        }

        if (!isEmailValid(email)) {
            errors.email = 'The email is not valid';
        }

        if (dedicatedSlackChannel?.length && !dedicatedSlackChannel?.startsWith('C')) {
            errors.dedicatedSlackChannel = 'The Dedicated Slack Channel is not valid';
        }

        return errors
    };

    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/ng-platform/freelancer');
        refresh();
    };

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm validate={validateFreelancerCreation}>
                <ReferenceArrayInput
                    reference="ng-platform/skill"
                    source="skillsAndTechnologies"
                    perPage={0}
                >
                    <SelectArrayInput optionText={'fullName'} />
                </ReferenceArrayInput>
                <TextInput source="name" required />
                <BooleanInput label="Is assigned" source="isAssigned" />
                <TextInput source="email" type="email" required />
                <TextInput source="dedicatedSlackChannel" resettable />
                <TextInput source="note" resettable />
            </SimpleForm>
        </Create>
    );
};
