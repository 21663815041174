import { Edit, SimpleForm, TextInput } from 'react-admin';
import * as React from 'react';

export const NgPlatformCategoryEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);
