export interface IConfig {
    API_URL: string;
    SLACK_CLIENT_ID: string;
}

const config = {
    staging: {
        API_URL: 'https://bobthebot2-staging.herokuapp.com',
        SLACK_CLIENT_ID: '2171509554.1536904379061',
    },
    production: {
        API_URL: 'https://bobthebot2-production.herokuapp.com',
        SLACK_CLIENT_ID: '2171509554.1623136648245',
    },
    local: {
        API_URL: 'http://localhost:3000',
        SLACK_CLIENT_ID: process.env.REACT_APP_SLACK_CLIENT_ID,
    },
};

const loadedConfig: IConfig = config[process.env.REACT_APP_ENV || 'local'];
export default loadedConfig;
