import * as React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin';

export const NgPlatformMemberCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/ng-platform/member');
        refresh();
    };
    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="email" />
            </SimpleForm>
        </Create>
    );
};
