import { Datagrid, List, NumberField, TextField } from "react-admin";

export const NtaSectionList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <NumberField source="orderKey" />
        </Datagrid>
    </List>
);
