import {
    BooleanInput,
    Filter,
    ReferenceInput,
    SelectInput,
    TextInput,
} from 'react-admin';

export const FreelancerFilters = (props) => 
    (<Filter {...props}>
        <TextInput
            label="Freelancer name"
            source="freelancerName"
            perPage={100}
            useRedirect="ng-platform/freelancers"
        >
        </TextInput>
        <BooleanInput
            label="Dedicated slack channel"
            source="dedicatedSlackChannel"
            perPage={100}
            useRedirect="ng-platform/freelancers"
        >
        </BooleanInput>
        <ReferenceInput
            label="Category"
            source="category"
            reference="ng-platform/category"
            perPage={100}
            useRedirect="ng-platform/freelancers"
        >
        <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            label="Skills"
            source="skill"
            reference="ng-platform/skill"
            perPage={100}
            useRedirect="ng-platform/freelancers"
        >
        <SelectInput optionText="fullName" />
        </ReferenceInput>
    </Filter>
);
