import { Create, maxLength, NumberInput, required, SimpleForm, TextInput } from 'react-admin';

export const NtaSectionCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="name" validate={[required(), maxLength(75)]} />
            <NumberInput source="orderKey" initialValue={0} step={1} validate={[required()]} />
        </SimpleForm>
    </Create>
);
