import * as React from 'react';
import {
    AutocompleteInput,
    SimpleForm,
    TextInput,
    Create,
    ReferenceInput,
    SelectInput,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';

export const NgPlatformSkillCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/ng-platform/skill');
        refresh();
    };
    return (
        <Create {...props} onSuccess={onSuccess}>
            <SimpleForm>
                <TextInput source="name" />
                <AutocompleteInput
                    source="seniority"
                    choices={[
                        { id: 'junior', name: 'junior' },
                        { id: 'regular', name: 'regular' },
                        { id: 'senior', name: 'senior' },
                        { id: 'lead', name: 'lead' },
                    ]}
                />
                <ReferenceInput
                    label="category"
                    source="category"
                    reference="ng-platform/category"
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
            </SimpleForm>
        </Create>
    );
};
