import * as React from 'react';
import {
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    SelectArrayInput,
    Create,
    useNotify,
    useRefresh,
    useRedirect,
} from 'react-admin';

export const NgPlatformAgencyCreate = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify(`Changes saved`);
        redirect('/ng-platform/agency');
        refresh();
    };
    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <TextInput source="name" />
                <ReferenceArrayInput
                    reference="ng-platform/tag"
                    source="tags"
                    perPage={100}
                >
                    <SelectArrayInput optionText={'name'} />
                </ReferenceArrayInput>
                <TextInput source={'slackChannelId'} />
                <TextInput source="contactPersonEmail" />
            </SimpleForm>
        </Create>
    );
};
