import * as React from 'react';
import { Notification, defaultTheme, LoginComponent } from 'react-admin';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import config from '../config';
import logo from '../boblogo.png';

const useStyles = makeStyles({
    root: {
        padding: '20px',
        textAlign: 'center',
        margin: 'auto',
    },
});

export const MyLoginPage: LoginComponent = ({ theme }) => {
    const slackUrl = `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=${config.SLACK_CLIENT_ID}`;
    const classes = useStyles();

    return (
        <ThemeProvider theme={createTheme(defaultTheme)}>
            <div className={'loginPage'}>
                <div className={classes.root}>
                    <img src={logo} className={'logo'} alt={'Bob Logo'} />
                    <h1>Bob The Bot 2.0 Admin Panel</h1>
                    <h3>Login with slack</h3>
                    <a href={slackUrl}>
                        <img
                            alt="Sign in with Slack"
                            height="40"
                            width="172"
                            src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                            srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
                        />
                    </a>
                    <p>Environment: {process.env.REACT_APP_ENV}</p>
                </div>
            </div>
            <Notification />
        </ThemeProvider>
    );
};
