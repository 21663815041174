import * as React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const NgPlatformSkillList = (props) => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="seniority" />
            <ReferenceField
                label="category"
                source="category"
                reference="ng-platform/category"
            >
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);
