import * as React from 'react';
import {
    List,
    Datagrid,
    TextField,
    EmailField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField,
} from 'react-admin';
import { AgencyFilters } from './AgencyFilter';

export const NgPlatformAgencyList = (props) => (
    <List filters={<AgencyFilters />} sort={{ field: 'name', order: 'ASC' }} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceArrayField
                label="Tags"
                reference="ng-platform/tag"
                source="tags"
                sortable={false}
            >
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <TextField source="slackChannelId" sortable={false} />
            <EmailField source="contactPersonEmail" />
        </Datagrid>
    </List>
);
